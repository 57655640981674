import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { connect } from "react-redux";
import {
  updateUserDetail,
  updateUserRole,
  updateUserRolesList,
} from "../redux/actions/userDataActions";

import * as Api from "../api";

import SettingTextField from "./SettingTextField";
import SettingSelectField from "./SettingSelectField";
import Loader from "./Loader";
import AlertMsg from "./AlertMsg";

import "./../assets/css/custom.css";

import { ReactComponent as CloseIcon } from "../assets/images/close_black.svg";

import {
  RESTAURANT_ADMIN_ROLE_ID,
  RESTAURANT_USER_ROLE_ID,
  THEME_COLOR,
  USER_ROLE_LIST,
} from "../constants";
import { THEME_MODE } from "../constants/Theme";

let user_role_list = USER_ROLE_LIST;

const AddUser = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userRole, setUserRole] = useState("");
  const [password, setPassword] = useState("");
  const [pinVal, setPinVal] = useState("");
  const [confirmPinVal, setConfirmPinVal] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    if (props.editData) {
      let data = props.editData;
      setUsername(data.name);
      setEmail(data.email);
      setPhone(data.phoneNumber);
      setPinVal(data.pin);
      setConfirmPinVal(data.pin);
      setUserRole(data._idUserRoles);
    }
  }, [props.editData]);

  useEffect(() => {
    setError(null);
    if (!props.modelVisible) {
      setUsername("");
      setEmail("");
      setPhone("");
      setUserRole(RESTAURANT_USER_ROLE_ID);
      setPassword("");
      setConfirmPassword("");
      setPinVal("");
      setConfirmPinVal("");
      setIsLoading(false);
      setMsgAlert({ open: false, message: "", msgType: "error" });
    }
  }, [props.modelVisible]);

  const onSubmit = () => {
    let err = null;
    if (username.trim() == "") {
      err = { username: "Username is required" };
    }

    if (userRole == RESTAURANT_ADMIN_ROLE_ID) {
      if (email.trim() == "") {
        err = { ...err, email: "Email is required" };
      } else {
        const reg = /^[\w+]+([\.-]?[\w+]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(email) == false) {
          err = { ...err, email: "Invalid Email" };
        }
      }

      if (phone.trim() == "") {
        err = { ...err, phone: "Phone Number is required" };
      } else {
        if (/[^0-9]/g.test(phone)) {
          err = { ...err, phone: "Invalid Phone Number" };
        }
      }
    }

    if (pinVal.trim() == "") {
      err = { ...err, pin: "Pin is required" };
    } else {
      if (pinVal.length != 4) {
        err = {
          ...err,
          pin: "Pin must contain 4 digits",
        };
      }
    }

    if (confirmPinVal.trim() == "") {
      err = { ...err, confirmPin: "Confirm Pin is required" };
    } else {
      if (pinVal !== confirmPinVal) {
        err = { ...err, confirmPin: "Pin Mismatch" };
      }
    }

    if (
      (props.editData == null ||
        password.trim() != "" ||
        props.editData?.password == "") &&
      userRole == RESTAURANT_ADMIN_ROLE_ID
    ) {
      if (password.trim() == "") {
        err = { ...err, password: "Password is required" };
      } else {
        if (password.length < 8) {
          err = {
            ...err,
            password: "Password must contain at least 8 characters",
          };
        }
      }

      if (confirmPassword.trim() == "") {
        err = { ...err, confirmPassword: "Confirm Password is required" };
      }

      if (password !== confirmPassword) {
        err = { ...err, confirmPassword: "Password Mismatch" };
      }
    }

    if (userRole.trim() == "") {
      err = { ...err, userRole: "User Role is required" };
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      name: username,
      email: null,
      phoneNumber: null,
      pin: pinVal,
      _idUserRoles: userRole,
      userRole:
        userRole == RESTAURANT_ADMIN_ROLE_ID
          ? "Admin"
          : userRole == RESTAURANT_USER_ROLE_ID
          ? "User"
          : "",
    };

    if (userRole == RESTAURANT_ADMIN_ROLE_ID) {
      data = {
        ...data,
        email: email,
        phoneNumber: phone,
      };

      if (props.editData) {
        if (password.trim() != "") {
          data = {
            ...data,
            password: password,
          };
        }
      } else {
        data = {
          ...data,
          password: password,
        };
      }
    } else {
      data = {
        ...data,
        password: null,
      };
    }

    if (props.editData) {
      data = {
        ...props.editData,
        ...data,
      };
    }

    onSubmitAddUser(data);
  };

  const onSubmitAddUser = (data) => {
    setIsLoading(true);
    let data1 = {
      name: data.name,
      pin: data.pin,
    };
    if (userRole == RESTAURANT_ADMIN_ROLE_ID || props.editData?._idUser) {
      data1 = { ...data1, email: data.email, phoneNumber: data.phoneNumber };
    }
    if (
      data?.password &&
      ((password.trim() != "" && userRole == RESTAURANT_ADMIN_ROLE_ID) ||
        userRole == RESTAURANT_USER_ROLE_ID)
    ) {
      data1 = { ...data1, password: data.password };
    }
    if (userRole == RESTAURANT_USER_ROLE_ID) {
      data1 = { ...data1, password: null };
    }

    let datas = JSON.stringify(data1);
    Api.addUpdateUser(
      datas,
      props.editData?._idUser ? props.editData._idUser : null
    ).then((response) => {
      if (response.success) {
        if (props.editData) {
          createUserRoles({ ...data, password: data.password }, null);
        } else {
          let user_data = response.data;
          createUserRoles(
            {
              ...data,
              password: data1.password,
              _idRestaurant: props.restaurantId,
              _idUser: user_data?._id,
            },
            user_data
          );
        }
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const createUserRoles = (data, userData) => {
    let param = {
      _idUser: data._idUser,
      _idRestaurant: data._idRestaurant,
      _idUserRoles: data._idUserRoles,
    };
    let data1 = JSON.stringify(param);
    Api.createUpdateUserRoles(
      data1,
      null,
      data?.userRoleId ? data.userRoleId : null,
      props.restaurantId
    ).then((response) => {
      if (response.success) {
        let user_detail = {
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
        };
        if (props.pinUserId == "" || props.pinUserId == null) {
          if (data._idUser == props.userId) {
            props.updateUserRole(data._idUserRoles);
            props.updateUserDetail(user_detail);
          }
        } else {
          if (data._idUser == props.pinUserId) {
            props.updateUserRole(data._idUserRoles);
            props.updateUserDetail(user_detail);
          }
        }
        if (props.editData) {
          let uList = [...props.userRolesList];
          let fIndex = uList.findIndex((x) => x._idUser == data._idUser);
          if (fIndex > 0) {
            if (uList[fIndex]?.userDetails.length) {
              uList[fIndex] = {
                ...uList[fIndex],
                _idUserRoles: data._idUserRoles,
                userDetails: [
                  {
                    ...uList[fIndex]?.userDetails[0],
                    phoneNumber: data.phoneNumber,
                    pin: data.pin,
                    name: data.name,
                    email: data.email,
                  },
                ],
              };
              props.updateUserRolesList(uList);
            }
          }
          props.onSubmit(data);
        } else {
          props.updateUserRolesList([
            ...props.userRolesList,
            {
              ...response.data,
              userDetails: [userData],
            },
          ]);
          let user_data = {
            ...data,
            userRoleId: response.data._id,
          };
          props.onSubmit(user_data);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Modal
      style={styles.modelView}
      open={props.modelVisible}
      onClose={props.onClose}
    >
      <Grid item xs={12} sx={styles.mainView}>
        <Grid item xs={12} sx={styles.headerView}>
          <Typography sx={styles.titleText}>
            {props.editData ? "Update" : "Add New"} User
          </Typography>
          <Box className="cursor-pointer" onClick={() => props.onToggle(false)}>
            <CloseIcon width={45} height={45} />
          </Box>
        </Grid>
        {isLoading ? (
          <Loader height={{ height: window.innerHeight - 150 }} />
        ) : null}
        {!isLoading ? (
          <Grid item xs={12} mt={3} sx={styles.mainGrid}>
            <SettingSelectField
              page={"addUser"}
              value={userRole}
              setValue={setUserRole}
              backgroundColor={theme_mode.inputBg2}
              label={"User Role"}
              placeholder={"Enter here"}
              required={true}
              variant={"filled"}
              menuList={user_role_list}
              error={error?.userRole}
            />
            <Box mt={2}>
              <SettingTextField
                page={"addUser"}
                value={username}
                setValue={setUsername}
                backgroundColor={theme_mode.inputBg2}
                label={"User Name"}
                placeholder={"Enter here"}
                required={true}
                variant={"filled"}
                error={error?.username}
              />
            </Box>
            {userRole == RESTAURANT_ADMIN_ROLE_ID ? (
              <>
                <Box mt={2}>
                  <SettingTextField
                    page={"addUser"}
                    value={email}
                    setValue={setEmail}
                    backgroundColor={theme_mode.inputBg2}
                    label={"Email"}
                    placeholder={"Enter here"}
                    required={true}
                    variant={"filled"}
                    type={"email"}
                    error={error?.email}
                  />
                </Box>
                <Box mt={2}>
                  <SettingTextField
                    page={"addUser"}
                    value={phone}
                    setValue={(value) => setPhone(value.replace(/[^0-9]/g, ""))}
                    backgroundColor={theme_mode.inputBg2}
                    label={"Mobile Number"}
                    placeholder={"Enter here"}
                    required={true}
                    variant={"filled"}
                    type={"number"}
                    error={error?.phone}
                  />
                </Box>
              </>
            ) : null}

            <Box mt={2}>
              <SettingTextField
                page={"addUser"}
                value={pinVal}
                setValue={(value) => setPinVal(value.replace(/[^0-9]/g, ""))}
                backgroundColor={theme_mode.inputBg2}
                label={"Pin"}
                placeholder={"Enter here"}
                required={true}
                variant={"filled"}
                type={"number"}
                error={error?.pin}
              />
            </Box>

            <Box mt={2}>
              <SettingTextField
                page={"addUser"}
                value={confirmPinVal}
                setValue={(value) =>
                  setConfirmPinVal(value.replace(/[^0-9]/g, ""))
                }
                backgroundColor={theme_mode.inputBg2}
                label={"Confirm Pin"}
                placeholder={"Enter here"}
                required={true}
                variant={"filled"}
                type={"number"}
                error={error?.confirmPin}
              />
            </Box>

            {userRole == RESTAURANT_ADMIN_ROLE_ID ? (
              <>
                <Box mt={2}>
                  <SettingTextField
                    page={"addUser"}
                    value={password}
                    setValue={setPassword}
                    backgroundColor={theme_mode.inputBg2}
                    label={"Password"}
                    placeholder={"Enter here"}
                    required={true}
                    variant={"filled"}
                    type={"password"}
                    error={error?.password}
                  />
                </Box>
                <Box mt={2}>
                  <SettingTextField
                    page={"addUser"}
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    backgroundColor={theme_mode.inputBg2}
                    label={"Re Enter Password"}
                    placeholder={"Enter here"}
                    required={true}
                    variant={"filled"}
                    type={"password"}
                    error={error?.confirmPassword}
                  />
                </Box>
              </>
            ) : null}
            <Grid item xs={12} my={4}>
              <Box sx={styles.btnMainView}>
                <Button
                  type="button"
                  onClick={() => props.onToggle(false)}
                  sx={{ ...styles.btnView, ...styles.cancelBtn }}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={() => onSubmit()}
                  sx={{ ...styles.btnView, ...styles.saveBtn }}
                >
                  {props.editData ? "Update" : "Add"} User
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Grid>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserRole: (data) => dispatch(updateUserRole(data)),
    updateUserDetail: (data) => dispatch(updateUserDetail(data)),
    updateUserRolesList: (data) => dispatch(updateUserRolesList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    pinUserId: state.userData.pinUserId,
    userId: state.userData.userId,
    userRolesList: state.userData.userRolesList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);

const styles1 = (Theme) => ({
  modelView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  mainView: {
    backgroundColor: Theme.modal,
    width: { md: "50%", xs: "98%" },
    borderRadius: 3,
    "&:focus": {
      outline: "none",
    },
    overflow: "hidden",
  },
  headerView: {
    backgroundColor: Theme.modalHeaderBg,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 80,
    px: 3,
  },
  titleText: {
    color: Theme.lightText,
    fontFamily: "InterBold",
    fontSize: 20,
  },
  mainGrid: {
    px: 3,
    height: window.innerHeight - 180,
    overflow: "auto",
  },
  btnMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mt: 2,
  },
  btnView: {
    cursor: "pointer",
    textTransform: "capitalize",
    fontFamily: "InterSemiBold",
    fontSize: 15,
    borderRadius: "9px",
    height: 44,
    width: "100%",
  },
  cancelBtn: {
    mr: 1,
    border: "2px solid" + Theme.btnBg5Border,
    color: Theme.text,
    backgroundColor: Theme.btnBg5,
    "&:hover": {
      backgroundColor: Theme.btnBg5 + " !important",
    },
  },
  saveBtn: {
    ml: 1,
    backgroundColor: Theme.btnBg4,
    color: Theme.lightText,
    "&:hover": {
      backgroundColor: Theme.btnBg4 + " !important",
    },
  },
});
